import axios from "axios";
import { useStaticQuery, graphql } from "gatsby";

export const GetURL = (url) => {

  const data = useStaticQuery(graphql`
    query {
	  allStrapiAllMenus {
	    edges {
	      node {
	        Alias
	        Label
	        URL
	        Main_Parent {
	          Alias
	          Label
	          URL
	        }
	        Sub_Parent {
	          Alias
	          Label
	          URL
	        }
	      }
	    }
	  }
    }
  `);
  
  if (url) {
    return url;
  }
}

export const isSold = (status='') => {
	console.log('test =>  status ', status)
	const soldSTatus = ['sold', 'sold sstc', 'let', 'let agreed'];
	return soldSTatus.includes(status.toLowerCase());
}

export const getitems = async ({url, setTestimonials}) => {
    try {
      const { data } = await axios.get(url, {
		headers: {
			Authorization:
				`Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
		}
	  }) // could be from env files
      setTestimonials(data)
    } catch (error) {
      // cache it if fail/error;
      console.error(error)
    }
}